<template>
  <div class="imgcard">
    <imgcard-item :div-id=0>
      <img slot="imgSrc" src="~images/baoguansx.svg" />
      <div slot="h1">报关手续</div>
      <div slot="h3">各国报关在手续繁琐</div>
      <div slot="p">各国海关对货品的申报都有不同要求，针对部分产品需要许可证，配额商品，强制认证等，手续繁锁复杂。</div>
    </imgcard-item>
     <imgcard-item :div-id=1>
      <img slot="imgSrc" src="~images/qingguan.svg" />
      <div slot="h1">清关效率</div>
      <div slot="h3">清关效率低下</div>
      <div slot="p">清关最常见的为海关估价，对产品申报价值偏低而遭遇退货，退港等，人工核查查验 ，对清关流程解释不到位，导致大量货物不能顺利入境。</div>
    </imgcard-item> 
    <imgcard-item :div-id=2>
      <img slot="imgSrc" src="~images/shuifei.svg" />
      <div slot="h1">税费</div>
      <div slot="h3">税费计算复杂</div>
      <div slot="p">跨境电商货物海关申报种类繁多，不同产地区域、国家，征收计算法则不同，享受优惠关税不同。</div>
    </imgcard-item>
     <imgcard-item :div-id=3>
      <img slot="imgSrc" src="~images/kuchun.svg" />
      <div slot="h1">库存</div>
      <div slot="h3">库存管理周期滞后</div>
      <div slot="p">跨境电商在不同国家自建海外仓，商品进销管理难以管理，零散库存补货出货物流成本难以控制，不利于整体协调及成本控制管理。</div>
    </imgcard-item>
  </div>
</template>
<script>
import ImgcardItem from "components/plugin/imgCard/ImgCardItem.vue";
export default {
  components: {
    ImgcardItem,
  },
};
</script>
<style lang="less" scoped>
.imgcard {
  display: flex;
}
@media screen and(max-width:768px) {
    .imgcard{
        flex-direction: column;
    }
}

</style>