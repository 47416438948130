<template>
  <div class="imgcardItem" ref="test">
    <div class="imgcontent">
      <div
        class="bannerline"
        :style="'background-position-x: ' + countWidth + 'px'"
      ></div>
      <slot name="imgSrc"><img src="~images/express.svg" /></slot>
      <h1><slot name="h1">专注</slot></h1>
    </div>
    <div class="des">
      <h3><slot name="h3">最快30天内可上线</slot></h3>
      <p>
        <slot name="p">
          内容，测试内容，内容，测试内容。内容，测试内容。内容，测试内容。内容，测试内容。内容，测试内容。
        </slot>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      divWidth: 0,
    };
  },
  props: {
    divId: {
      type: Number,
      default: 3,
    },
  },
  computed: {
    countWidth() {
      return -(this.divWidth * this.divId + 80);
    },
  },
  mounted() {
    let w = this.$refs.test.clientWidth;
    this.divWidth = w;
    let _this = this;
    window.onresize = function () {
      var onreW = _this.$refs.test.clientWidth;
      _this.divWidth = onreW;
      console.log(onreW);
    };
  },
};
</script>
<style lang="less" scoped>
.imgcardItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 20px;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  min-height: 360px;
  background-image: url(~images/cardimg/card-bgimg-200x200.png);
  border: 1px solid var(--color-menu);
  transition: all 0.5s ease-in-out;
  box-shadow: 0px 0px 3px #999;
}
@media screen and (max-width: 980px) {
  .imgcardItem {
    margin: 5px !important;
  }
  .bannerline {
    background-position-y: -300px !important;
  }
}

.imgcardItem:hover {
  transform: scale(1.05);
}
.imgcontent {
  background: var(--color-menu);
  color: white;
  align-items: center;
  height: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;

  .bannerline {
    background-image: url("~images/contentImgs/banner-line.svg");
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: 700%;
    background-repeat: no-repeat;

    background-position-y: -120px;
  }
  h1 {
    z-index: 1;
  }
  img {
    width: 40px;
    z-index: 1;
    align-items: center;
  }
}
.des {
  flex: 1;

  h3 {
    font-weight: bold;
  }
  p {
    padding: 0 10%;
    margin: 0;
    color: #6f6f6f;
    font-weight: 500;
    font-size: 14px;
    text-align: left;
  }
}
</style>