<template>
  <div class="container">
    <div class="banner">
      <img src="~assets/images/contentImgs/banner-retailers.svg" />
    </div>
    <div class="innerBox">
      <div class="desc">
        <h1>- 跨境电商的困境 -</h1>
        <img-card></img-card>
      </div>
    </div>
    <div class="innerBox bg">
      <div class="desc">
        <h1>- 助力跨境电商 -</h1>
        <span
          >完善的物流体系与国外网络，专业的物流人员，助力您的跨境电商轻松管理。从电商平台客户下单到货物国内打包物流至国外清关物流，再到国外仓实现自动分发派送至买家等一条龙服务。让您只专注产品营销，高效拓展，我们为你解决一切物流供应链上的后顾之优。</span
        >
      </div>
    </div>
    <div class="innerBox">
      <div class="desc">
        <ldes-rimg>
          <h3 slot="revContentTitleH3"></h3>
          <h1 slot="revContentTitleH1">跨境电商物流</h1>
          <span slot="revContentSpan"
            >依托全球物流服务网络及国国内外多家快递公司航空公司的长期合作关系，为当前电商企业进出口端口的分发，采购，仓储、清关等全方位服务，减轻电商企业在物流、清关、税务上相关成本，提升整个物流的低成本、高时效、便捷的服务。</span
          >
          <img
            slot="revImglable"
            class="img"
            src="../assets/images/contentImgs/supply.png"
          />
        </ldes-rimg>
      </div>
    </div>
  </div>
</template>
<script>
import LdesRimg from "../components/plugin/imgdesc/ldes-rimg.vue";
import ImgCard from "components/plugin/imgCard/ImgCard.vue";
export default {
  data() {
    return {};
  },
  components: {
    ImgCard,LdesRimg
  },
};
</script>
<style lang="less" scoped>
@import url("../assets/css/banner.less");
@import url("../assets/css/container.less");
.banner {
  background-image: linear-gradient(
    180deg,
    #d5e0e6 0%,
    #cbdfe7 51%,
    #e2d3d0 100%
  );
}
.bg {
  span {
    min-width: 768px;
    width: 100%;
    text-align: center;
  }
}
</style>